import { Modal, Box, Typography, Button } from '@mui/material';

export const CompletionModal = ({ open, onClose }) => {
  // Create a wrapper function that first calls the onClose handler and then refreshes the page
  const handleCloseAndRefresh = () => {
    onClose(); // First call the onClose prop function to update the state or do any necessary cleanup
    window.location.reload(); // Then refresh the page
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseAndRefresh} // Use the new wrapper function here
      aria-labelledby="completion-modal-title"
      aria-describedby="completion-modal-description"
    >
      <Box sx={{ 
        position: 'absolute',
        top: '50%', left: '50%', 
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        bgcolor: 'background.paper', 
        p: 4, 
        boxShadow: 24,
        borderRadius: 2 // You can style this as you like
      }}>
        <Typography id="completion-modal-title" variant="h6" component="h2">
          Your Questionnaire is marked as complete.
        </Typography>
        <Button sx ={{mt:2}}color="primary" variant="contained" onClick={handleCloseAndRefresh}>Close</Button> {/* Use the wrapper function here as well */}
      </Box>
    </Modal>
  );
};
