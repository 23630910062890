import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, TextField, Grid, Box } from '@mui/material';
import axiosInstance from '../../utils/axios';
import { convertKeysToCamelCase } from '../../utils/misc'; // Assuming this utility function is available
import { useQuestionnaireContext } from '../../components/QuestionnaireContext';

export default function PropertyAmenities({updateFormData, reloadTrigger}) {

  const [formState, setFormState] = useState({
    hasVendingMachines: false,
    hasInformationCenter: false,
    hasDaycare: false,
    hasAmusementAreas: false,
    provideAmusementServices: false,
    hasWebsite: false,
    hasSpecialEvents: false,
    hasExecutiveOfficeServices: false,
    hasCommonAreaConference: false,
    hasSpecialServices: false, // New state for special services
    hasSpecialServicesCommonArea: false, // New state for special services
    hasFitnessFacilities: false,
    providesConcierge: false,
    conciergeProvidedMarket: false,
    conciergeProvidedByThirdParty: false,
    hasOtherAmenities: false,
    hasMobileApp: false,
    mobileAppMarketServices: false,
    websiteIncomeDescribe: '',
    hasWebsiteIncome: false,
    hasOtherAmenitiesDescribe: '',
    rentedSpaceThirdParty: false,
    propertyMaintainsVending: false, 
    spaceLeasedThirdPartyOperation: false, 
    infoBoothsProvided: false, 
    propertyReceivesProceeds: false, 
    amusementAreasRented: false, 
    amusementServiceDescription: '',
    eventsProvidedSimilarProperties: false, 
    eventDescription: '',
    executiveServicesDescription: '',
    tenantContractExecutiveServices: '',
    billingExecutiveServices: '',
    specialServicesDescription: '',
    specialServicesDescriptionCommonArea: '',
    fitnessProvidedMarket: false, 
    facilityAttendant: false, 
    leasedToThirdParty: false, 
    conciergeIncome: false, 
    tenantChargedDirectly: false, 
    mobileAppServices: '',
    daycareLeasedThirdParty: false,
    incomeFromDaycare: false
  });

  const { selectedQuestionnaireId } = useQuestionnaireContext();

  useEffect(() => {
    const questionnaireId = sessionStorage.getItem('selectedQuestionnaireId');
    if (questionnaireId) {
      axiosInstance.get('/api/property-amenities/', {
        params: { questionnaire_id: questionnaireId }
      })
      .then(response => {
        const data = convertKeysToCamelCase(response.data);
        setFormState(data);
        // Update your form data here as well
        updateFormData('propertyAmenities', data);
      })
      .catch(error => {
        console.error('Failed to fetch contact info:', error);
        // Handle error appropriately
      });
    }
  },[selectedQuestionnaireId]); // Depend on reloadTrigger to re-run the effect

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: checked
      };
      updateFormData('propertyAmenities', updatedState);
      return updatedState;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => {
      const updatedInfo = { ...prevState, [name]: value };
      updateFormData('propertyAmenities', updatedInfo);
      return updatedInfo;
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {/* Vending Machines, ATMs, Lockers, Mail Services */}
       <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="hasVendingMachines"
                checked={formState.hasVendingMachines}
                onChange={handleCheckboxChange}
              />
            }
            label="Does the Property have vending machines, ATMs, lockers, or mail drop off or mail pickup services?"
          />
        </Grid>
        {formState.hasVendingMachines && (
          <>
            <Box ml={3}>
              <Grid item xs={12} sx={{ paddingLeft: 4 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="rentedSpaceThirdParty"
                      checked={formState.rentedSpaceThirdParty || false} // Add `|| false` to ensure consistency if the field might be undefined
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Is the space rented to a third-party supplier?"
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingLeft: 4 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="propertyMaintainsVending"
                      checked={formState.propertyMaintainsVending || false} // Add `|| false` to ensure consistency if the field might be undefined
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Is the Property responsible for installing, maintaining, and repairing the vending machines?"
                />
              </Grid>
            </Box>
          </>
        )}
        {/* Information Center */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="hasInformationCenter"
                checked={formState.hasInformationCenter}
                onChange={handleCheckboxChange}
              />
            }
            label="Does the Property have an information center where patrons can buy shopping bags, rent wheel chairs or strollers, acquire items with the Property's logo, or obtain information on where stores are located?"
          />
        </Grid>
          {formState.hasInformationCenter && (
            <Box ml={3}>
              <Grid item xs={12} sx={{ paddingLeft: 4 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="spaceLeasedThirdPartyOperation"
                      checked={formState.spaceLeasedThirdPartyOperation || false} // Assuming you have this in formState
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Is the space for the information center leased to a third party for operation?"
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingLeft: 4 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="infoBoothsProvided"
                      checked={formState.infoBoothsProvided || false} // Assuming you have this in formState
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Are information booths provided at similar properties in the same geographic market?"
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingLeft: 4 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="propertyReceivesProceeds"
                      checked={formState.propertyReceivesProceeds || false} // Assuming you have this in formState
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Does the property receive the proceeds from the sale of any the items mentioned above?"
                  />
                </Grid>
              </Box>
            )}

            {/* Daycare Center */}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="hasDaycare"
              checked={formState.hasDaycare}
              onChange={handleCheckboxChange}
            />
          }
          label="Is there a daycare center on the premises?"
        />
      </Grid>
      {formState.hasDaycare && (
        <>
          <Box ml={3}>
            <Grid item xs={12} sx={{ paddingLeft: 4 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="daycareLeasedThirdParty"
                    checked={formState.daycareLeasedThirdParty || false} // Assuming you have this in formState
                    onChange={handleCheckboxChange}
                  />
                }
                label="Is the space leased to a third party operator?"
              />
            </Grid>
            <Grid item xs={12} sx={{ paddingLeft: 4 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="incomeFromDaycare"
                    checked={formState.incomeFromDaycare || false} // Assuming you have this in formState
                    onChange={handleCheckboxChange}
                  />
                }
              label="Is any income derived from the daycare other than rent?"
            />
          </Grid>
        </Box>
      </>
    )}

      {/* Amusement Areas */}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="hasAmusementAreas"
              checked={formState.hasAmusementAreas}
              onChange={handleCheckboxChange}
            />
          }
          label="Does the Property contain any amusement areas such as carousels, or skating rinks?"
        />
      </Grid>
      {formState.hasAmusementAreas && (
        <Box ml={3}>
          <Grid item xs={12} sx={{ paddingLeft: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="amusementAreasRented"
                  checked={formState.amusementAreasRented || false}
                  onChange={handleCheckboxChange}
                />
              }
              label="Is the space for the amusement areas rented to third party operators?"
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingLeft: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="provideAmusementServices"
                  checked={formState.provideAmusementServices}
                  onChange={handleCheckboxChange}
                />
              }
              label="Does the Property perform any services for the amusement area other than providing utilities and space?"
            />
          </Grid>
          {formState.provideAmusementServices && (
            <Grid item xs={12} ml={4}>
              <TextField
                id="amusementServiceDescription"
                name="amusementServiceDescription"
                label="Please describe any services the property provides for the amusement area other than providing utilities and space."
                fullWidth
                margin="normal"
                variant="standard"
                onChange={handleInputChange}
                value={formState.amusementServiceDescription || ''}
              />
            </Grid>
          )}
        </Box>
      )}

      {/* Website */}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="hasWebsite"
              checked={formState.hasWebsite}
              onChange={handleCheckboxChange}
            />
          }
          label="Does the Property maintain a web site with information about the property?"
        />
      </Grid>
      {formState.hasWebsite && (
        <>
          <Grid item xs={12} ml={3}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="hasWebsiteIncome"
                  checked={formState.hasWebsiteIncome}
                  onChange={handleCheckboxChange}
                />
              }
              label="Does the Property derive any income through the web site from sales of gift certificates, or any other source?"
            />
          </Grid>
          {formState.hasWebsiteIncome && (
            <Grid item xs={12} ml={3}>
              <TextField
                id="websiteIncomeDescribe"
                name="websiteIncomeDescribe"
                label="Please Describe"
                fullWidth
                multiline
                rows={2}
                variant="standard"
                onChange={handleInputChange}
                value={formState.websiteIncomeDescribe || ''}
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="hasSpecialEvents"
              checked={formState.hasSpecialEvents}
              onChange={handleCheckboxChange}
            />
          }
          label="Does the Property sponsor any special events for tenants or their customers (such as live music, holiday events, or charitable events)?"
        />
      </Grid>
      {formState.hasSpecialEvents && (
        <>
          <Grid item xs={12} ml={3}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="eventsProvidedSimilarProperties"
                  checked={formState.eventsProvidedSimilarProperties || false}
                  onChange={handleCheckboxChange}
                />
              }
              label="Are such events provided at similar properties in the same geographic area?"
            />
          </Grid>
          <Grid item xs={12} ml={4}>
            <TextField
              id="eventDescription"
              name="eventDescription"
              label="Please list and provide a brief description of the events."
              fullWidth
              multiline
              rows={2}
              variant="standard"
              value={formState.eventDescription || ''}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Executive Office Services */}
          <Grid item xs={12} ml={3}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="hasExecutiveOfficeServices"
                  checked={formState.hasExecutiveOfficeServices}
                  onChange={handleCheckboxChange}
                />
              }
              label="Are Executive Office Services provided at the property?"
            />
          </Grid>
          {formState.hasExecutiveOfficeServices && (
            <>
              <Grid item xs={12} ml={4}>
                <TextField
                  id="executiveServicesDescription"
                  name="executiveServicesDescription"
                  label="Please describe the services provided."
                  fullWidth
                  multiline
                  rows={2}
                  variant="standard"
                  value={formState.executiveServicesDescription || ''}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} ml={4}>
                <TextField
                  id="tenantContractExecutiveServices"
                  name="tenantContractExecutiveServices"
                  label="Who do the Tenants contract with for the service?"
                  fullWidth
                  multiline
                  rows={2}
                  variant="standard"
                  value={formState.tenantContractExecutiveServices || ''}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} ml={4}>
                <TextField
                  id="billingExecutiveServices"
                  name="billingExecutiveServices"
                  label="Who bills and collects fees for the service?"
                  fullWidth
                  multiline
                  rows={2}
                  variant="standard"
                  value={formState.billingExecutiveServices || ''}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} ml={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="hasCommonAreaConference"
                      checked={formState.hasCommonAreaConference}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Are Common Area Conference Facilities provided at the property?"
                />
              </Grid>
              {formState.hasCommonAreaConference && (
                <>
                  <Grid item xs={12} ml={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          name="hasSpecialServices"
                          checked={formState.hasSpecialServices}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Are any special services offered in connection with the facilities, such as catering, party clean-up, etc.?"
                    />
                  </Grid>
                  {formState.hasSpecialServices && (
                    <Grid item xs={12} ml={4}>
                      <TextField
                        id="specialServicesDescription"
                        name="specialServicesDescription"
                        label="Please briefly describe the terms of compensation for provision of services"
                        fullWidth
                        multiline
                        rows={4}
                        variant="standard"
                        value={formState.specialServicesDescription || ''}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} ml={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          name="hasSpecialServicesCommonArea"
                          checked={formState.hasSpecialServicesCommonArea}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Are any special services offered in connection with the Common Area Conference facilities, such as catering, party clean-up, etc.?"
                    />
                  </Grid>

                  {formState.hasSpecialServicesCommonArea && (
                    <Grid item xs={12} ml={4}>
                      <TextField
                        id="specialServicesDescriptionCommonArea"
                        name="specialServicesDescriptionCommonArea"
                        label="Please briefly describe the terms of compensation for provision of services"
                        fullWidth
                        multiline
                        rows={4}
                        variant="standard"
                        value={formState.specialServicesDescriptionCommonArea || ''}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="hasFitnessFacilities"
              checked={formState.hasFitnessFacilities}
              onChange={handleCheckboxChange}
            />
          }
          label="Does the Property provide fitness facilities to tenants?"
        />
      </Grid>
      {formState.hasFitnessFacilities && (
        <>
          <Grid item xs={12} ml={3}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="fitnessProvidedMarket"
                  checked={formState.fitnessProvidedMarket || false}
                  onChange={handleCheckboxChange}
                />
              }
              label="Are fitness services provided at similar properties in the same geographic market?"
            />
          </Grid>
          <Grid item xs={12} ml={3}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="facilityAttendant"
                  checked={formState.facilityAttendant || false}
                  onChange={handleCheckboxChange}
                />
              }
              label="Does the fitness facility have an attendant and/or fitness instructor?"
            />
          </Grid>
          <Grid item xs={12} ml={3}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="leasedToThirdParty"
                  checked={formState.leasedToThirdParty || false}
                  onChange={handleCheckboxChange}
                />
              }
              label="Is the space for the facility leased to a third party operator without any subsidy or involvement from the Property?"
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="providesConcierge"
              checked={formState.providesConcierge}
              onChange={handleCheckboxChange}
            />
          }
          label="Does the Property provide Concierge Services to tenants? (e.g., message services, dry-cleaning drop-off or pick-up, key service, shoe shine, restaurant reservations, ticket sales, telephone service, etc.)"
        />
      </Grid>
      {formState.providesConcierge && (
        <>
          <Grid item xs={12} ml={3}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="conciergeProvidedMarket"
                  checked={formState.conciergeProvidedMarket}
                  onChange={handleCheckboxChange}
                />
              }
              label="Are such services provided at similar properties in the same geographic market?"
            />
          </Grid>
          {formState.conciergeProvidedMarket && (
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="conciergeProvidedByThirdParty"
                    checked={formState.conciergeProvidedByThirdParty}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Is the concierge provided by a Third Party?"
              />
              {formState.conciergeProvidedByThirdParty && (
                <Grid item xs={12} ml={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        name="conciergeIncome"
                        checked={formState.conciergeIncome || false}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Does the Property receive any income from the provision of concierge services?"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        name="tenantChargedDirectly"
                        checked={formState.tenantChargedDirectly || false}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="If provided by a Third Party, is the tenant charged directly for special concierge services?"
                  />
                </Grid>
              )}
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="hasOtherAmenities"
              checked={formState.hasOtherAmenities}
              onChange={handleCheckboxChange}
            />
          }
          label="Does the Property provide any other amenities or unique services that distinguish it from its competitors?"
        />
      </Grid>
      {formState.hasOtherAmenities && (
        <Grid item xs={12} ml={3}>
          <TextField
            id="hasOtherAmenitiesDescribe"
            name="hasOtherAmenitiesDescribe"
            label="Please Describe"
            fullWidth
            multiline
            rows={2}
            variant="standard"
            onChange={handleInputChange}
            value={formState.hasOtherAmenitiesDescribe || ''}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="hasMobileApp"
              checked={formState.hasMobileApp}
              onChange={handleCheckboxChange}
            />
          }
          label="Does the Property maintain a mobile app that contains information about the property?"
        />
      </Grid>
      {formState.hasMobileApp && (
        <>
          <Grid item xs={12} ml={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="mobileAppMarketServices"
                  checked={formState.mobileAppMarketServices}
                  onChange={handleCheckboxChange}
                />
              }
              label="Does the mobile app only provide services that would be provided to similar properties in the same geographic market?"
            />
          </Grid>
          <Grid item xs={12} ml={4}>
            <TextField
              id="mobileAppServices"
              name="mobileAppServices"
              label="Please list all services provided to tenants on the mobile app."
              fullWidth
              multiline
              rows={4}
              variant="standard"
              onChange={handleInputChange}
              value={formState.mobileAppServices || ''}
            />
          </Grid>
        </>
      )}
      </Grid>
    </React.Fragment>
  );
}
