import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { convertKeysToCamelCase } from '../../utils/misc'; // Assuming this utility function is available
import { useQuestionnaireContext } from '../../components/QuestionnaireContext';


export default function TenantOperations({updateFormData, reloadTrigger}) {

  const [FormState, setFormState] = useState({
    participateInPromotions: false,
    promotionDetails: '',
    procuresDiscountsForTenantsDescribe: '',
    providesDiscountsAndAccess: false,
    procuresDiscountsForTenants: false,
    providesPreferredProvidersList: false,
    receivesReferralFees: false,
    receivesFeesForTenantAccess: false,
    providesBusinessServices: false,
    businessServicesInArea: false,
    businessServicesDetails: '',
    providesPrivateFacilities: false,
    privateFacilitiesInArea: false,
    providesSpecialServices: false,
    specialServicesDetails: '',
    providesAdSpace: false,
    leasesPersonalProperty: false,
    leaseIdentifier: '',
    personalPropertyDescription: '',
    exceedsFairMarketValue: false,
    marketingProvidedAtSimilarArea: false,
    providesPreferredProvidersListDescribe: '',
    receivesReferralFeesDescribe: '',
    receivesFeesForTenantAccessDescribe: '',
    providesPrivateFacilitiesDescribe: '',
    providesAdSpaceDescribe: '',
  });

  const { selectedQuestionnaireId } = useQuestionnaireContext();

  useEffect(() => {
    const questionnaireId = sessionStorage.getItem('selectedQuestionnaireId');
    if (questionnaireId) {
      axiosInstance.get('/api/tenant-operations/', {
        params: { questionnaire_id: questionnaireId }
      })
      .then(response => {
        const data = convertKeysToCamelCase(response.data);
        setFormState(data);
        // Update your form data here as well
        updateFormData('propertyAmenities', data);
      })
      .catch(error => {
        console.error('Failed to fetch contact info:', error);
        // Handle error appropriately
      });
    }
  },[selectedQuestionnaireId]); // Depend on reloadTrigger to re-run the effect

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: checked
      };
      updateFormData('tenantOperations', updatedState);
      return updatedState;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => {
      const updatedInfo = { ...prevState, [name]: value };
      updateFormData('tenantOperations', updatedInfo);
      return updatedInfo;
    });
  };


  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {/* Merchant's Associations and Promotional Activities */}
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" checked={FormState.participateInPromotions} name="participateInPromotions" onChange={handleCheckboxChange} />}
            label="Are the Tenants required to participate in merchant's associations, marketing funds, or other promotional activities?"
          />
        </Grid>
        {FormState.participateInPromotions && (
          <>
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={<Checkbox color="secondary"/>} 
                checked={FormState.marketingProvidedAtSimilarArea}
                name = "marketingProvidedAtSimilarArea"
                onChange={handleCheckboxChange}
                label="Is a marketing fund or merchant's association provided at similar properties within the same geographic area?"
              />
            </Grid>
            <Grid item xs={12} ml={4}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={FormState.promotionDetails}
                name="promotionDetails"                
                onChange={handleInputChange}
                label="Please briefly describe any unique marketing or promotional activities at the property."
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" checked={FormState.providesDiscountsAndAccess} name="providesDiscountsAndAccess" onChange={handleCheckboxChange} />}
            label="Does the Property provide discounts and access to the tenant base?"
          />
        </Grid>

        {/* Property procures discounts on goods and services for tenants */}
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" checked={FormState.procuresDiscountsForTenants} name="procuresDiscountsForTenants" onChange={handleCheckboxChange} />}
            label="Does the Property procure discounts on goods and services for tenants?"
          />
        </Grid>
        {FormState.procuresDiscountsForTenants && (
          <>
            <Grid item xs={12} ml={4}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={FormState.procuresDiscountsForTenantsDescribe} 
                name="procuresDiscountsForTenantsDescribe"
                onChange={handleInputChange}
                label="Please Describe"
              />
            </Grid>
          </>
        )}

        {/* Property provides a list of preferred service providers to tenants */}
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" checked={FormState.providesPreferredProvidersList} name="providesPreferredProvidersList" onChange={handleCheckboxChange} />}
            label="Does the Property provide a list of preferred service providers to tenants?"
          />
        </Grid>
        {FormState.providesPreferredProvidersList && (
          <>
            <Grid item xs={12} ml={4}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={FormState.providesPreferredProvidersListDescribe} 
                name="providesPreferredProvidersListDescribe"
                onChange={handleInputChange}
                label="Please Describe"
              />
            </Grid>
          </>
        )}

        {/* Property receives any referral fees or commissions from tenants */}
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" checked={FormState.receivesReferralFees} name="receivesReferralFees" onChange={handleCheckboxChange} />}
            label="Does the Property receive any referral fees or commissions from tenants?"
          />
        </Grid>
        {FormState.receivesReferralFees && (
          <>
            <Grid item xs={12} ml={4}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={FormState.receivesReferralFeesDescribe} name="receivesReferralFeesDescribe"
                onChange={handleInputChange}
                label="Please Describe"
              />
            </Grid>
          </>
        )}

        {/* Property receives any fees for access to its tenant base */}
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" checked={FormState.receivesFeesForTenantAccess} name="receivesFeesForTenantAccess" onChange={handleCheckboxChange} />}
            label="Does the Property receive any fees for access to its tenant base?"
          />
        </Grid>
        {FormState.receivesFeesForTenantAccess && (
          <>
            <Grid item xs={12} ml={4}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={FormState.receivesFeesForTenantAccessDescribe} name="receivesFeesForTenantAccessDescribe"
                onChange={handleInputChange}
                label="Please Describe"
              />
            </Grid>
          </>
        )}
<Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={FormState.providesBusinessServices}
                name="providesBusinessServices"
                onChange={handleCheckboxChange}
              />
            }
            label="Does the Property provide any services directly related to the business operations of its Tenants (such as inspecting or advising tenant product displays, secret shopper programs, etc.)"
          />
        </Grid>
        {FormState.providesBusinessServices && (
          <>
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={FormState.businessServicesInArea}
                    name="businessServicesInArea"                    
                    onChange={handleCheckboxChange}
                  />
                }
                label="Are such services provided at similar properties in the same geographic area?"
              />
            </Grid>
            <Grid item xs={12} ml={4}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={FormState.businessServicesDetails} 
                name="businessServicesDetails"
                onChange={handleInputChange}
                label="Please briefly describe the services."
              />
            </Grid>
          </>
        )}
 <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={FormState.providesPrivateFacilities}
                name="providesPrivateFacilities"                
                onChange={handleCheckboxChange}
              />
            }
            label="Does the Property provide any private club space, rooftop terrace, or similar facilities to tenants at reduced prices, or provide facilities that are not otherwise open to the general public?"
          />
        </Grid>
        {FormState.providesPrivateFacilities && (
          <>
            <Grid item xs={12} ml={4}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={FormState.providesPrivateFacilitiesDescribe}
                name="providesPrivateFacilitiesDescribe"                
                onChange={handleInputChange}
                label="Please Describe"
              />
            </Grid>
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={FormState.privateFacilitiesInArea}
                    name="privateFacilitiesInArea"                    
                    onChange={handleCheckboxChange}
                  />
                }
                label="Are such facilities provided at similar properties in the geographic area?"
              />
            </Grid>
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={FormState.providesSpecialServices}
                    name="providesSpecialServices"                    
                    onChange={handleCheckboxChange}
                  />
                }
                label="Are any special services offered in connection with the facilities?"
              />
              </Grid>
              {FormState.providesSpecialServices && (
                <Grid item xs={12} ml={4}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={FormState.specialServicesDetails}
                    name="specialServicesDetails"                    
                    onChange={handleInputChange}
                    label="Please briefly describe the terms of compensation for provision of services."
                  />
                </Grid>
              )}
          </>
        )}

        {/* Advertising Space */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={FormState.providesAdSpace}
                name="providesAdSpace"                
                onChange={handleCheckboxChange}
              />
            }
            label="Does the property provide any interior or exterior billboard space, elevator displays, etc.?"
          />
        </Grid>
        {FormState.providesAdSpace && (
          <Grid item xs={12} ml={4}>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={FormState.providesAdSpaceDescribe}
              name="providesAdSpaceDescribe"              
              onChange={handleInputChange}
              label="Please Describe"
            />
          </Grid>
        )}

<Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={FormState.leasesPersonalProperty}
                name="leasesPersonalProperty"                
                onChange={handleCheckboxChange}
              />
            }
            label="Are any significant or unusual amounts of personal property (e.g., furniture, equipment, appliances, light fixtures, etc.) leased to tenants or other persons?"
          />
        </Grid>
        {FormState.leasesPersonalProperty && (
          <>
            {/* Conditional questions */}
            <Grid item xs={12} ml={4}>
              <TextField
                fullWidth
                label="Identify the relevant lease"
                value={FormState.leaseIdentifier}
                name="leaseIdentifier"                
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} ml={4}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Describe the personal property leased"
                value={FormState.personalPropertyDescription}
                name="personalPropertyDescription"                
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={FormState.exceedsFairMarketValue}
                    name="exceedsFairMarketValue"                    
                    onChange={handleCheckboxChange}
                  />
                }
                label="Does the fair market value of the personal property subject to the lease exceed 15 percent of the combined fair market value of the personal property and the real property subject to the lease?"
              />
            </Grid>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
}

