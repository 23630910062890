import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from '../login/login';
import PrivateRoute from '../../auth/PrivateRoute';
import PropertyQuestionnaire from '../forms/PropertyQuestionnaire';
import QuestionnaireModal from '../../components/QuestionnaireModal';
import { isAuthenticated } from '../../auth/Auth'; 
import '../../utils/api.js'
import { checkTokenExpiry } from '../../utils/auth';
import PostLoginNavigation from '../../components/PostLoginNavigation';
import { QuestionnaireProvider } from '../../components/QuestionnaireContext';
import PasswordResetPage from '../login/PasswordReset';
import ActivationPage from '../login/ActivationPage';



function App() {
  // State to determine if the modal completed
  const [modalCompleted, setModalCompleted] = useState(false);

  useEffect(() => {
    checkTokenExpiry();

    // Check for token expiration every minute
    const intervalId = setInterval(checkTokenExpiry, 60000); 
    return () => clearInterval(intervalId);
  }, []); 

  // Function to handle modal completion 
  const handleModalClose = () => {
    setModalCompleted(true);
  };


  return (

    <QuestionnaireProvider>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/property-questionnaire"
          element={
            <PrivateRoute>
              {!modalCompleted ? (
                <QuestionnaireModal isOpen={true} onClose={handleModalClose} />
              ) : (
                <PropertyQuestionnaire />
              )}
            </PrivateRoute>
          }
        />
        <Route path="/password-reset-confirm/:uidb64/:token/" element={<PasswordResetPage />} />
        <Route path="/activate/:uidb64/:token/" element={<ActivationPage />} />
        <Route
          path="*"
          element={<Navigate to={isAuthenticated() ? "/property-questionnaire" : "/login"} replace />}
        />
      </Routes>
    </Router>
    </QuestionnaireProvider>
  );
}

export default App;
