import {useState} from 'react';
import { IconButton, Checkbox, TextField, Box, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const ValidatedPasswordField = ({password, setPassword, setIsPasswordFieldFocused, onBlur,isPasswordFieldFocused}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [hasMinLength, setHasMinLength] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  const handleNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Password Validations
    setHasMinLength(newPassword.length >= 8);
    setHasUppercase(/[A-Z]/.test(newPassword));
    setHasLowercase(/[a-z]/.test(newPassword));
    setHasSpecialChar(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword));
  }
  
  const handleTogglePasswordVisibility = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  return (
    <>
      <TextField
        onBlur={onBlur}
        margin="normal"
        required
        fullWidth
        name="newPassword"
        label="New Password"
        type={showPassword ? "text" : "password"}
        id="newPassword"
        value={password}
        onChange={handleNewPasswordChange}
        // Made use of endAdornment prop of InputProps to display the eye icon
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={handleTogglePasswordVisibility}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          )
        }}
        onFocus={() => setIsPasswordFieldFocused(true)}
        autoFocus
      />
          {isPasswordFieldFocused && (
            <Box sx={{ mt: 2 }}>
              <Box sx={{ pl: 2, mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Checkbox 
                    checked={hasMinLength} 
                    size="small"
                    sx={{
                      color: hasMinLength ? 'success.main' : 'action.disabled',
                      '&.Mui-checked': {
                        color: 'success.main',
                      },
                      padding: 0,
                      marginRight: '8px'
                    }} 
                  />
                  <Typography variant="body2">8 Characters</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Checkbox 
                    checked={hasUppercase} 
                    size="small"
                    sx={{
                      color: hasUppercase ? 'success.main' : 'action.disabled',
                      '&.Mui-checked': {
                        color: 'success.main',
                      },
                      padding: 0,
                      marginRight: '8px'
                    }} 
                  />
                  <Typography variant="body2">Uppercase Letters</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Checkbox 
                    checked={hasLowercase} 
                    size="small"
                    sx={{
                      color: hasLowercase ? 'success.main' : 'action.disabled',
                      '&.Mui-checked': {
                        color: 'success.main',
                      },
                      padding: 0,
                      marginRight: '8px'
                    }} 
                  />
                  <Typography variant="body2">Lowercase Letters</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Checkbox 
                    checked={hasSpecialChar} 
                    size="small"
                    sx={{
                      color: hasSpecialChar ? 'success.main' : 'action.disabled',
                      '&.Mui-checked': {
                        color: 'success.main',
                      },
                      padding: 0,
                      marginRight: '8px'
                    }} 
                  />
                  <Typography variant="body2">Special Characters</Typography>
                </Box>
              </Box>
            </Box>
      )}
    </>
  );
};
