import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  Button,
  DialogActions,
  Chip
} from '@mui/material';
import { useQuestionnaireContext } from './QuestionnaireContext';
import axiosInstance from '../utils/axios';
import { DateFormatter } from '../utils/dateFormatter';

function QuestionnaireModal({ isOpen, onClose}) {
  const [activeQuestionnaires, setActiveQuestionnaires] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { handleQuestionnaireChange } = useQuestionnaireContext();
  
  const handleLogout = useCallback(() => {
    // Clearing user data from local storage or any other client-side storage being used
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    // Add any additional cleanup here if needed

    // Redirect user to login or root page, replace '/' with the path you use for your login page if it's different
    window.location.href = '/';
    
  }, []);

  useEffect(() => {
    if (isOpen) {
      const fetchActiveQuestionnaires = async () => {
        setIsLoading(true);
        setError(null);
        try {
          const response = await axiosInstance.get('/api/get-questionnaires/');
          setActiveQuestionnaires(response.data);

          // If no questionnaires are returned, create a new one.
          if (response.data.length === 0) {
            await onCreateNew();
          }
        } catch (err) {
          setError(err.response.data.detail);
        } finally {
          setIsLoading(false);
        }
      };
      fetchActiveQuestionnaires();
    }
  }, [isOpen]);

  const onCreateNew = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post('/api/create-questionnaire/', {});
      const newQuestionnaire = response.data;
      setActiveQuestionnaires([newQuestionnaire]);
      sessionStorage.setItem('selectedQuestionnaireId', newQuestionnaire.id);
      handleQuestionnaireChange(newQuestionnaire.id);
      onClose(); // Closing modal after creating a new questionnaire
    } catch (err) {
      setError('Failed to create new questionnaire');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectExisting = (selectedQuestionnaire) => {
    handleQuestionnaireChange(selectedQuestionnaire.id);
    onClose(); // Closing modal when a questionnaire is selected

    // onQuestionnaireSelect(selectedQuestionnaire.id); // Call the passed-in callback
  };

  return (
    <Dialog
      open={isOpen}
      disablebackdropclick="true"
      disableEscapeKeyDown
      aria-labelledby="modal-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="modal-title">What Questionnaire do you want to work on?</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Box display="flex" justifyContent="center" pt={2}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <List dense>
            {activeQuestionnaires.map((questionnaire) => (
              <ListItem button key={questionnaire.id} onClick={() => onSelectExisting(questionnaire)}>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography variant="body1" sx={{width:'35%', overflow:'hidden'}}>
                    {questionnaire.contact_information && questionnaire.contact_information.name_of_property
                      ? questionnaire.contact_information.name_of_property
                      : "no name given"}
                  </Typography>
                  <DateFormatter dateString={questionnaire.date_created} />
                  {questionnaire.status === 'completed' && (
                    <Chip label="Completed" size="small" sx={{ width: '85px',bgcolor: '#008578', color: 'white', mx: 1 }} />
                  )}
                  {questionnaire.status === 'in_progress' && (
                    <Chip label="In Progress" size="small" sx={{ width: '85px',bgcolor: '#fae2be', color: 'black', mx: 1 }} />
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }}>
          {/* The left side button */}
          <Button onClick={handleLogout} color="error" variant="contained">
            Logout
          </Button>
        </Box>

        {/* The right side button */}
        <Button onClick={onCreateNew} color="primary" variant="contained" disabled={!!error}>
          Create New Questionnaire
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QuestionnaireModal;
