// QuestionnaireContext.js
import React, { createContext, useState, useContext } from 'react';

export const QuestionnaireContext = createContext();

export const useQuestionnaireContext = () => useContext(QuestionnaireContext);

export const QuestionnaireProvider = ({ children }) => {
  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState(null);

  const handleQuestionnaireChange = (id) => {
    setSelectedQuestionnaireId(id);
    sessionStorage.setItem('selectedQuestionnaireId', id);
  };

  return (
    <QuestionnaireContext.Provider
      value={{
        selectedQuestionnaireId,
        handleQuestionnaireChange,
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};
