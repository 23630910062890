import axios from 'axios';

// Define the base URL for production; for development, use an empty string
const BASE_URL_PRODUCTION = "https://property-questionnaire-backend.azurewebsites.net/"; // Replace with your production API URL
const BASE_URL_DEVELOPMENT = "";

const baseURL = process.env.REACT_APP_ENV== 'production' 
    ? BASE_URL_PRODUCTION 
    : BASE_URL_DEVELOPMENT;

const axiosInstance = axios.create({
    baseURL: baseURL
});
export default axiosInstance; // Continue to export the configured axiosInstance
