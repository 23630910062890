import React from 'react';
import { Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const DateFormatter = ({ dateString }) => {
  // Replace with the actual date string from your data source
  const dateStr = dateString || '2024-03-20T20:53:11.966486Z';
  
  // Parse the date string into a Date object
  const date = parseISO(dateStr);

  // Get the user's timezone; you can also use a specific timezone if needed
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert UTC date to user's timezone
  const zonedDate = utcToZonedTime(date, userTimeZone);

  // Format the date to the desired output - day-month-year hour:minute
  const formattedDate = format(zonedDate, 'MM-dd-yyyy hh:mm a', { timeZone: userTimeZone });

  return (
    <Typography variant="body2" color="textSecondary">
      {formattedDate}
    </Typography>
  );
};
