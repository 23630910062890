import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/home/App'; // Assuming this component now includes <Router> and defined routes

const isDebuggingBackend = process.env.REACT_APP_BACKEND_DEBUG === 'true'; // Set this environment variable when you start your React app
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

if (isDebuggingBackend) {
  // Render without StrictMode when debugging the backend
  root.render(
    <App />
  );
} else {
  // Render with StrictMode while not debugging
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
