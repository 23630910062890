import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import { Dialog, DialogTitle,DialogContent,DialogContentText,DialogActions, IconButton, Checkbox, ClickAwayListener, Box, Button, Container, TextField, Typography, Alert, CircularProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function PasswordResetPage() {

  const navigate = useNavigate();
  const { uidb64, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);
  const [hasMinLength, setHasMinLength] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordFieldRef = useRef();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOpenErrorModal = (msg) => {
      setErrorMessage(msg);
      setOpenErrorModal(true);
  }

  // On closing the modal, navigate to the password reset page with the isResettingPassword state set to true
  const handleCloseErrorModal = () => {
      setOpenErrorModal(false);
      navigate('/login', { state: { isResettingPassword: true } }); 
  }

  const handleOpenSuccessModal = (msg) => {
      setOpenSuccessModal(true);
  }

  // On closing the modal, navigate to the password reset page with the isResettingPassword state set to true
  const handleCloseSuccessModal = () => {
      setOpenSuccessModal(false);
      navigate('/login'); 
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleNewPasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);

    // Password Validations
    setHasMinLength(password.length >= 8);
    setHasUppercase(/[A-Z]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasSpecialChar(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password));
  }

  const handlePasswordFieldBlur = (event) => {
    if (passwordFieldRef.current && !passwordFieldRef.current.contains(event.target)) {
      setIsPasswordFieldFocused(false);
    }
  }

  const handlePasswordResetSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess(false);
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const minLengthRequirement = newPassword.length >= 8;
    const hasUppercase = /[A-Z]/.test(newPassword);
    const hasLowercase = /[a-z]/.test(newPassword);
    const hasSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword);
    if (!(hasSpecialChar && hasLowercase && hasUppercase && minLengthRequirement)) {
      setError('Password does not meet the requirements');
      return;
    }

    try {
      let response = await axiosInstance.post(`/api/password-reset-confirm/${uidb64}/${token}/`, {
        new_password1: newPassword,
        new_password2: confirmPassword,
      });
      setSuccess(true);
      // Optionally set a timeout to redirect to the login page after successful reset
      handleOpenSuccessModal();
    } catch (error) {
      handleOpenErrorModal(error.response.data.error);
    }
  };

  return (
    <>
    <ClickAwayListener onClickAway={handlePasswordFieldBlur}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Your Password
        </Typography>
        <Box component="form" onSubmit={handlePasswordResetSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="New Password"
          type={showPassword ? "text" : "password"}
          id="newPassword"
          value={newPassword}
          onChange={handleNewPasswordChange}
          // Made use of endAdornment prop of InputProps to display the eye icon
          InputProps={{
            endAdornment: (
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            )
          }}
          inputRef={passwordFieldRef}
          onFocus={() => setIsPasswordFieldFocused(true)}
          autoFocus
        />
          {isPasswordFieldFocused && (
            <Box sx={{ mt: 2 }}>
              <Box sx={{ pl: 2, mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Checkbox 
                    checked={hasMinLength} 
                    size="small"
                    sx={{
                      color: hasMinLength ? 'success.main' : 'action.disabled',
                      '&.Mui-checked': {
                        color: 'success.main',
                      },
                      padding: 0,
                      marginRight: '8px'
                    }} 
                  />
                  <Typography variant="body2">8 Characters</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Checkbox 
                    checked={hasUppercase} 
                    size="small"
                    sx={{
                      color: hasUppercase ? 'success.main' : 'action.disabled',
                      '&.Mui-checked': {
                        color: 'success.main',
                      },
                      padding: 0,
                      marginRight: '8px'
                    }} 
                  />
                  <Typography variant="body2">Uppercase Letters</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Checkbox 
                    checked={hasLowercase} 
                    size="small"
                    sx={{
                      color: hasLowercase ? 'success.main' : 'action.disabled',
                      '&.Mui-checked': {
                        color: 'success.main',
                      },
                      padding: 0,
                      marginRight: '8px'
                    }} 
                  />
                  <Typography variant="body2">Lowercase Letters</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Checkbox 
                    checked={hasSpecialChar} 
                    size="small"
                    sx={{
                      color: hasSpecialChar ? 'success.main' : 'action.disabled',
                      '&.Mui-checked': {
                        color: 'success.main',
                      },
                      padding: 0,
                      marginRight: '8px'
                    }} 
                  />
                  <Typography variant="body2">Special Characters</Typography>
                </Box>
              </Box>
            </Box>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onFocus={() => setIsPasswordFieldFocused(false)}
          />
          {error && (
            <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
              {error}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    </Container>
    </ClickAwayListener>
    <Dialog open={openErrorModal} onClose={handleCloseErrorModal}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {errorMessage}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseErrorModal} color="primary" autoFocus>Go to password reset</Button>
        </DialogActions>
    </Dialog>
    <Dialog
      open={openSuccessModal}
      onClose={handleCloseSuccessModal}
    >
      <DialogTitle>Success</DialogTitle>
      <DialogContentText>
        <Alert severity="success">
          Your Password Was Reset
        </Alert>
      </DialogContentText>
      <DialogActions>
        <Button onClick={handleCloseSuccessModal} color="primary" autoFocus>
          Go to Login Page
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
}
