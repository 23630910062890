
// Utility function to convert camelCase to snake_case
export const toSnakeCase = (str) => {
  return str.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);
};

// Convert the keys of an object from camelCase to snake_case
export const convertKeysToSnakeCase = (obj) => {
  const newObj = {};
  for (let key in obj) {
    newObj[toSnakeCase(key)] = obj[key];
  }
  return newObj;
};
export const toCamelCase = (str) => {
  return str.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
};

// Convert the keys of an object from snake_case to camelCase
export const convertKeysToCamelCase = (obj) => {
  const newObj = {};
  for (let key in obj) {
    newObj[toCamelCase(key)] = obj[key];
  }
  return newObj;
};
