import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { convertKeysToCamelCase } from '../../utils/misc';
import { useQuestionnaireContext } from '../../components/QuestionnaireContext';

const ContactInformation = ({ updateFormData, reloadTrigger }) => {
  const [contactInfo, setContactInfo] = useState({
    nameOfPerson: '',
    nameOfProperty: '',
    legalName: '',
  });

  const { selectedQuestionnaireId } = useQuestionnaireContext();


  useEffect(() => {
    const questionnaireId = sessionStorage.getItem('selectedQuestionnaireId');
    if (questionnaireId) {
      axiosInstance.get('/api/contact-information/', {
        params: { questionnaire_id: questionnaireId }
      })
      .then(response => {
        const data = convertKeysToCamelCase(response.data);
        setContactInfo(data);
        // Update your form data here as well
        updateFormData('contactInformation', data);
      })
      .catch(error => {
        console.error('Failed to fetch contact info:', error);
        // Handle error appropriately
      });
    }
  },[selectedQuestionnaireId]); // Depend on reloadTrigger to re-run the effect

  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setContactInfo(prevState => {
      const updatedInfo = { ...prevState, [name]: value };
      // Potentially update parent form data whenever something changes
      updateFormData('contactInformation', updatedInfo);
      return updatedInfo;
    });
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Contact Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="nameOfPerson"
            name="nameOfPerson"
            label="Name of person completing questionnaire"
            fullWidth
            autoComplete="name"
            variant="standard"
            value={contactInfo.nameOfPerson || ''}  // Set 'value' prop
            onChange={handleInputChange}              // Set 'onChange' prop
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="nameOfProperty"
            name="nameOfProperty"
            label="Name of property"
            fullWidth
            autoComplete="off"
            variant="standard"
            value={contactInfo.nameOfProperty || ''}  // Set 'value' prop
            onChange={handleInputChange}                // Set 'onChange' prop
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="legalName"
            name="legalName"
            label="Legal name"
            fullWidth
            autoComplete="off"
            variant="standard"
            value={contactInfo.legalName || ''}  // Set 'value' prop
            onChange={handleInputChange}                // Set 'onChange' prop
          />
        </Grid>
        {/* Assuming the rest of the form is removed, if not, keep or modify as needed */}
      </Grid>
    </React.Fragment>
  );
};

export default ContactInformation;
