import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle,DialogContent,DialogContentText,DialogActions, IconButton,ClickAwayListener, CheckboxClickAwayListener, Alert, Button, TextField, Typography, Container, Box, CircularProgress } from '@mui/material';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import { ValidatedPasswordField } from '../../components/ValidatedPasswordField';

function ActivationPage() {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { uidb64, token } = useParams(); // Now capturing uidb64 and token from the URL
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);
  const passwordFieldRef = useRef();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  useEffect(() => {
    const validateActivationLink = async () => {
      setLoading(true); // Indicate that validation is in progress
      try {
        const response = await axiosInstance.get(`/api/validate-activation/${uidb64}/${token}/`);
        setLoading(false); // End loading indication
        if (!response.data.isValid) {
          // If the link is invalid and the server returns a message, show the confirmation dialog
          setDialogMessage(response.data.message);
          setConfirmationDialogOpen(true);
        }
      } catch (e) {
        setLoading(false); // End loading indication on error
        // Show generic error message in confirmation dialog
        setDialogMessage('There was a problem validating your activation link.');
        setConfirmationDialogOpen(true);
      }
    };

    validateActivationLink();
  }, [uidb64, token, axiosInstance]);

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
    navigate('/login'); // Redirect user to login upon confirmation
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handlePasswordFieldBlur = (event) => {
    if (passwordFieldRef.current && !passwordFieldRef.current.contains(event.target)) {
      setIsPasswordFieldFocused(false);
    }
  }

  const handleOpenErrorModal = (msg) => {
      setErrorMessage(msg);
      setOpenErrorModal(true);
  }

  // On closing the modal, navigate to the password reset page with the isResettingPassword state set to true
  const handleCloseErrorModal = () => {
      setOpenErrorModal(false);
      navigate('/login'); 
  }

  const handleOpenSuccessModal = (msg) => {
      setOpenSuccessModal(true);
  }

  // On closing the modal, navigate to the password reset page with the isResettingPassword state set to true
  const handleCloseSuccessModal = () => {
      setOpenSuccessModal(false);
      navigate('/login'); 
  }

  const activateAccount = async () => {
    setError('');
    setSuccess(false);
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const minLengthRequirement = newPassword.length >= 8;
    const hasUppercase = /[A-Z]/.test(newPassword);
    const hasLowercase = /[a-z]/.test(newPassword);
    const hasSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword);
    if (!(hasSpecialChar && hasLowercase && hasUppercase && minLengthRequirement)) {
      setError('Password does not meet the requirements');
      return;
    }


    try {
      // Replace '/api/activate/' with your Django activation endpoint
      let response = await axiosInstance.post(`/api/password-reset-confirm/${uidb64}/${token}/`, {
        new_password1: newPassword,
        new_password2: confirmPassword,
      });

      setSuccess(true);
      // Optionally set a timeout to redirect to the login page after successful reset
      handleOpenSuccessModal();
    } catch (e) {
      handleOpenErrorModal(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Container component="main" maxWidth="xs">
      <ClickAwayListener onClickAway={handlePasswordFieldBlur}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Activate Your Account
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <ValidatedPasswordField
              password={newPassword}
              setPassword={setNewPassword}
              isPasswordFieldFocused={isPasswordFieldFocused}
              setIsPasswordFieldFocused={setIsPasswordFieldFocused}
              onBlur={() => setIsPasswordFieldFocused(false)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={activateAccount}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Activate Account'}
            </Button>
            {error && (
              <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                {error}
              </Alert>
            )}
          </Box>
        </Box>
      </ClickAwayListener>
    </Container>

    <Dialog open={openErrorModal} onClose={handleCloseErrorModal}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
            <DialogContentText>
              Your account is not active, please contact tax@jamestownlp.com
            </DialogContentText>
        </DialogContent>
    </Dialog>

    <Dialog
      open={openSuccessModal}
      onClose={handleCloseSuccessModal}
    >
      <DialogTitle>Success</DialogTitle>
      <DialogContentText>
        <Alert severity="success">
          Your account is now active!
        </Alert>
      </DialogContentText>
      <DialogActions>
        <Button onClick={handleCloseSuccessModal} color="primary" autoFocus>
          Go to Login Page
        </Button>
      </DialogActions>
    </Dialog>
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
      >
        <DialogTitle>{"Your Activation Link has Expired"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationDialogClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ActivationPage;
