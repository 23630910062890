import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import { Checkbox, FormControlLabel, Grid,TextField } from '@mui/material';
import { convertKeysToCamelCase } from '../../utils/misc'; // Assuming this utility function is available
import { useQuestionnaireContext } from '../../components/QuestionnaireContext';

export default function Other({updateFormData, reloadTrigger}) {

  const [FormState, setFormState] = useState({
    hasNonTenantAnchorStores:false,
    shareCommonAreas:false,
    providesUniqueServices:false,
    performsMaintenance:false,
    nonTenantStoresPayForMaintenance:false,
    hasRentSpaceReit:false,
    rentSpaceReitRelevantLease:false,
    rentSpaceReitPropertiesPerformed:false,
    rentSpaceReitDescribeServices:'',
})

  const { selectedQuestionnaireId } = useQuestionnaireContext();

  useEffect(() => {
    const questionnaireId = sessionStorage.getItem('selectedQuestionnaireId');
    if (questionnaireId) {
      axiosInstance.get('/api/other/', {
        params: { questionnaire_id: questionnaireId }
      })
      .then(response => {
        const data = convertKeysToCamelCase(response.data);
        setFormState(data);
        // Update your form data here as well
        updateFormData('other', data);
      })
      .catch(error => {
        console.error('Failed to fetch contact info:', error);
        // Handle error appropriately
      });
    }
  },[selectedQuestionnaireId]); // Depend on reloadTrigger to re-run the effect

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: checked
      };
      updateFormData('other', updatedState);
      return updatedState;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => {
      const updatedInfo = { ...prevState, [name]: value };
      updateFormData('other', updatedInfo);
      return updatedInfo;
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {/* Main question about non-tenant anchor or stores */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={FormState.hasNonTenantAnchorStores}
                name="hasNonTenantAnchorStores"
                onChange={handleCheckboxChange}
              />
            }
            label="Does the Property contain a non-tenant anchor or non-tenant stores?"
          />
        </Grid>
        {FormState.hasNonTenantAnchorStores && (
          <>
            {/* Conditional questions */}
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={<Checkbox color="secondary" checked={FormState.shareCommonAreas} onChange={handleCheckboxChange} />}
                name="shareCommonAreas"
                label="Does the non-tenant anchor or non-tenant store share any common areas with the property (including parking areas, exterior common areas, and enclosed shopping center areas)?"
              />
            </Grid>
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={<Checkbox color="secondary" checked={FormState.providesUniqueServices} onChange={handleCheckboxChange} />}
                name="providesUniqueServices"
                label="Are any services being provided by the Property to a non-tenant anchor or non-tenant store that are not provided at similar properties within the same geographic area?"
              />
            </Grid>
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={<Checkbox color="secondary" checked={FormState.performsMaintenance} onChange={handleCheckboxChange} />}
                name="performsMaintenance"
                label="Is any exterior maintenance or common area maintenance of the non-tenant anchor or non-tenant store performed by the Property owner?"
              />
              {/* Nested conditional question */}
              {FormState.performsMaintenance && (
                <Grid item xs={12} ml={8}>
                  <FormControlLabel
                    control={<Checkbox color="secondary" checked={FormState.nonTenantStoresPayForMaintenance} onChange={handleCheckboxChange} />}
                    name="nonTenantStoresPayForMaintenance"
                    label="Do the non-tenant anchors or non-tenant stores make payment to the Property Owner for this maintenance?"
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={FormState.hasRentSpaceReit}
                name="hasRentSpaceReit"
                onChange={handleCheckboxChange}
              />
            }
            label="Does the property rent space to a tenant that may also be providing services at the property and (or) at other properties in the REIT structure?"
          />
        </Grid>

        {FormState.hasRentSpaceReit && (
          <>
            {/* Conditional questions */}
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={<Checkbox color="secondary" checked={FormState.rentSpaceReitRelevantLease} onChange={handleCheckboxChange} />}
                name="rentSpaceReitRelevantLease"
                label="Identify the relevant lease"
              />
            </Grid>
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={<Checkbox color="secondary" checked={FormState.rentSpaceReitPropertiesPerformed} onChange={handleCheckboxChange} />}
                name="rentSpaceReitPropertiesPerformed"
                label="Identify the properties where services are performed"
              />
            </Grid>
          <Grid item xs={12} ml={3}>
          <TextField
            id="rentSpaceReitDescribeServices"
            label="Describe the services provided"
            fullWidth
            multiline
            rows={2}
            variant="standard"
              onChange={handleInputChange}
              value={FormState.rentSpaceReitDescribeServices}
              name="rentSpaceReitDescribeServices"
          />
          </Grid>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
}
