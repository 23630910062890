import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import {Typography,Grid,TextField,FormControlLabel,Checkbox,Box,FormLabel,FormGroup} from '@mui/material';
import { convertKeysToCamelCase } from '../../utils/misc'; // Assuming this utility function is available
import { useQuestionnaireContext } from '../../components/QuestionnaireContext';


export default function TransportationAndParking({updateFormData, reloadTrigger }) {

  const [formState, setFormState] = useState({
    providesTransportation: false,
    hasParkingFacilities: false,
    providesTransportation: false,
    transportServiceDescription: '',
    transportServiceProvider: '',
    vehicleOwnership: '',
    tenantContractDetails: '',
    billingAndCollection: '',
    incomeFromService: false,
    chargeStatedSeparately: false,
    competingProperties: '',
    masterLeasedParking: false,
    managedByThirdPartyParking: false,
    utilizedByTenantsParking: false,
    providedToSimilarPropertiesParking: false,
    servicesProvidedOtherThanMaintenance: false,
    reservedParkingProvided: false,
    valetServiceProvided: false,
    carWashServiceProvided: false,
  })

  const { selectedQuestionnaireId } = useQuestionnaireContext();

  useEffect(() => {
    const questionnaireId = sessionStorage.getItem('selectedQuestionnaireId');
    if (questionnaireId) {
      axiosInstance.get('/api/transportation-and-parking/', {
        params: { questionnaire_id: questionnaireId }
      })
      .then(response => {
        const data = convertKeysToCamelCase(response.data);
        setFormState(data);
        // Update your form data here as well
        updateFormData('transportationAndParking', data);
      })
      .catch(error => {
        console.error('Failed to fetch contact info:', error);
        // Handle error appropriately
      });
    }
  },[selectedQuestionnaireId]); // Depend on reloadTrigger to re-run the effect

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: checked
      };
      updateFormData('transportationAndParking', updatedState);
      return updatedState;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => {
      const updatedInfo = { ...prevState, [name]: value };
      updateFormData('transportationAndParking', updatedInfo);
      return updatedInfo;
    });
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Transportation and Parking
      </Typography>
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="providesTransportation"
              checked={formState.providesTransportation}
              onChange={handleCheckboxChange}
            />
          }
          label="Does the property provide any transportation services (e.g., shuttle bus service)?"
        />
        {formState.providesTransportation && (
          <Box ml={3}>
            <TextField
              id="transportServiceDescription"
              name="transportServiceDescription"
              label="Please provide a brief description of the service."
              fullWidth
              margin="normal"
              variant="standard"
              value={formState.transportServiceDescription }
              onChange={handleInputChange}
            />
            <TextField
              id="transportServiceProvider"
              name="transportServiceProvider"
              label="Who provides the service"
              fullWidth
              margin="normal"
              variant="standard"
              value={formState.transportServiceProvider }
              onChange={handleInputChange}
            />
            <TextField
              id="vehicleOwnership"
              name="vehicleOwnership"
              label="Who owns the vehicles used in providing the service?"
              fullWidth
              margin="normal"
              variant="standard"
              value={formState.vehicleOwnership }
              onChange={handleInputChange}
            />
            <TextField
              id="tenantContractDetails"
              name="tenantContractDetails"
              label="Who do tenants contract with for the service?"
              fullWidth
              margin="normal"
              variant="standard"
              value={formState.tenantContractDetails }
              onChange={handleInputChange}
            />
            <TextField
              id="billingAndCollection"
              name="billingAndCollection"
              label="Who bills and collects fees for the service?"
              fullWidth
              margin="normal"
              variant="standard"
              value={formState.billingAndCollection }
              onChange={handleInputChange}
            />
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="incomeFromService"
                    checked={formState.incomeFromService }
                    onChange={handleCheckboxChange}
                  />
                }
                label="Does the Property collect any type of income or pay any expense with regard to the service?"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="chargeStatedSeparately"
                    checked={formState.chargeStatedSeparately }
                    onChange={handleCheckboxChange}
                  />
                }
                label="Is any charge to the tenant separately stated?"
              />
            </Grid>
            <TextField
              id="competingProperties"
              name="competingProperties"
              label="List any competing properties that provide a similar service."
              fullWidth
              margin="normal"
              variant="standard"
              value={formState.competingProperties }
              onChange={handleInputChange}
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="hasParkingFacilities"
                checked={formState.hasParkingFacilities}
                onChange={handleCheckboxChange}
              />
            }
            label="Does the property have parking facilities?"
          />
      {formState.hasParkingFacilities && (
      <Box ml={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="masterLeasedParking"
                checked={formState.masterLeasedParking }
                onChange={handleCheckboxChange}
              />
            }
            label="Is the parking facility master leased under a traditional real estate lease to an unrelated third party?"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="managedByThirdPartyParking"
                checked={formState.managedByThirdPartyParking || false}
                onChange={handleCheckboxChange}
              />
            }
            label="Is the parking facility being managed by a third party operator?"
          />
        </Grid>
        <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="utilizedByTenantsParking"
              checked={ formState.utilizedByTenantsParking || false }
              onChange={handleCheckboxChange}
            />
          }
          label="Is the parking facility in, adjacent to, or near the rental property, such that it is utilized by the Property's tenants, and their guests, customers and subtenants?"
        />
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="providedToSimilarPropertiesParking"
              checked={formState.providedToSimilarPropertiesParking || false }
              onChange={handleCheckboxChange}
            />
          }
          label="Is parking provided to tenants and their guests, customers, and subtenants, for other properties of a similar class in the same geographic market in which this property is located?"
        />
        <Grid item xs={12}>
          <Typography variant="h6">Do any of the following situations apply to the parking facility:</Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="servicesProvidedOtherThanMaintenance"
                checked={formState.servicesProvidedOtherThanMaintenance || false}
                onChange={handleCheckboxChange}
              />
            }
            label="Services are provided other than customary maintenance, cleaning, lighting, and repairs; such services might include, e.g., collecting charges for parking, security, enforcement of reserved spots, jumpstarts, etc."
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="reservedParkingProvided"
                checked={formState.reservedParkingProvided || false}
                onChange={handleCheckboxChange}
              />
            }
            label="Reserved parking is provided"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="valetServiceProvided"
                checked={formState.valetServiceProvided }
                onChange={handleCheckboxChange}
              />
            }
            label="Valet service is provided"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="carWashServiceProvided"
                checked={formState.carWashServiceProvided }
                onChange={handleCheckboxChange}
              />
            }
            label="Car wash and detailing service is provided"
          />
        </Grid>
      </Box>
    )}
        </Grid>
        </Grid>
    </React.Fragment>
  );
}
