import axiosInstance from './axios'; // Import the custom axios instance that you've set up

// Function to refresh the access token using the refresh token
export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axiosInstance.post('/api/token/refresh/', {
      refresh: refreshToken
    });
    const { access } = response.data;
    localStorage.setItem('accessToken', access);
  } catch (error) {
    console.error('Failed to refresh token:', error);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    return Promise.reject(error);
  }
};

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the failing request was a login attempt
    const isLoginAttempt = originalRequest.url === '/api/token/';

    // If the failing request was a login attempt,
    // don't try to refresh the token or redirect
    // Instead, just let the error be rejected and handled in your catch block
    if (isLoginAttempt) {
      return Promise.reject(error);
    }

    // Your existing code...
    if (originalRequest.url === '/api/token/refresh/' || error.config._retry) {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      originalRequest._retry = true;

      try {
        await refreshAccessToken();
        originalRequest.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
        return axiosInstance(originalRequest); // Retry the original request with new token
      } catch (refreshError) {
        // Redirect to login on refresh token failure
        window.location.href = '/login';
        // Make sure to reject the promise after you redirected
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
