import React, { useState, useEffect } from 'react';
import { Typography, FormControlLabel, Checkbox, TextField, Grid, Box } from '@mui/material';
import axiosInstance from '../../utils/axios';
import { convertKeysToCamelCase } from '../../utils/misc'; // Assuming this utility function is available
import { useQuestionnaireContext } from '../../components/QuestionnaireContext';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

// ... Products and payment details remain the same

// Modal style
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Confirm({updateFormData, reloadTrigger}) {

  const [formState, setFormState] = useState({
    status: false
  });

  const [openModal, setOpenModal] = useState(false);

  const { selectedQuestionnaireId } = useQuestionnaireContext();

  useEffect(() => {
    const questionnaireId = sessionStorage.getItem('selectedQuestionnaireId');
    if (questionnaireId) {
      axiosInstance.get('/api/questionnaire/', {
        params: { questionnaire_id: questionnaireId }
      })
      .then(response => {
        const data = convertKeysToCamelCase(response.data);
        let status = data.status
        if(status==='in_progress'){
            data.status = false
          }
        if (status==='completed') {
            data.status= true
        }         
        setFormState(data);
        // Update your form data here as well
        updateFormData('confirm', data);
      })
      .catch(error => {
        console.error('Failed to fetch contact info:', error);
        // Handle error appropriately
      });
    }
  },[selectedQuestionnaireId]); // Depend on reloadTrigger to re-run the effect

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: checked
      };
      updateFormData('confirm', updatedState);
      return updatedState;
    });
  };

  const handleCloseModal = () => setOpenModal(false);

  return (
    <React.Fragment>
      {/* ... Rest of the component remains unchanged */}
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={formState.status}
            onChange={handleCheckboxChange}
            name="status"
          />
        }
        label="I verify that the information provided is correct and complete."
      />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You have successfully submitted the questionnaire.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
