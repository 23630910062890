import React, { useEffect, useState, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoginIcon from '@mui/icons-material/Login';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../auth/Auth';
import { useLocation } from 'react-router-dom';
import validator from 'validator';
import axiosInstance from '../../utils/api';


const defaultTheme = createTheme();

export default function Login() {

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleEmailBlur = () => {
    if (email.trim() !== "") {
      const isValid = validator.isEmail(email);
      setEmailError(!isValid);
    }
  }

  const { state } = useLocation();
  const historyState = state ? state.isResettingPassword : false;

  const [isResettingPassword, setIsResettingPassword] = useState(historyState);

const handlePasswordResetSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email').toLowerCase();
    const isValid = validator.isEmail(email);
    if (!isValid) {
      setEmailError(true);
      return;
    }
    else {
      setEmailError(false);
    }

  try {
    // Call the API endpoint to send a password reset email
    await axiosInstance.post(`api/password-reset/`, { email });
    // Show success message to users, e.g. using a toast notification
    // e.g. toast('Check your email for password reset instructions')

    // Optionally, toggle back to the sign-in view
    setIsResettingPassword(false);
  } catch (error) {
    console.error('Password reset failed:', error.response);
    // Handle errors, e.g. show an error notification
  }
};

  const navigate = useNavigate(); // Instantiate the navigate function
  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/property-questionnaire', { replace: true });
    }
  }, [navigate]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validator.isEmail(email);
    if (!isValid) {
      setEmailError(true);
      return;
    }
    else {
      setEmailError(false);
    }
    const data = new FormData(event.currentTarget);

    const loginCredentials = {
      email: data.get('email').toLowerCase(), // Assuming the backend expects 'username' parameter
      password: data.get('password'),
    };

    
    try {
      const response = await axiosInstance.post(`/api/token/`, loginCredentials);
      const { access, refresh } = response.data;
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);

      navigate('/property-questionnaire?new=true');
    } catch (error) {
      setPasswordError('Password is incorrect');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            {isResettingPassword ? <LockIcon />: <LoginIcon /> }
          </Avatar>
          <Typography component="h1" variant="h5">
            {isResettingPassword ? 'Reset Password' : 'Sign in'}
          </Typography>
        <Box component="form" onSubmit={isResettingPassword ? handlePasswordResetSubmit : handleSubmit} noValidate sx={{ mt: 1 }}>
          {isResettingPassword ? (
            // Password reset view
            <>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              error={emailError}
              helperText={emailError ? "Invalid email." : ""}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
            />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send Reset Link
              </Button>
            </>
          ) : (
            // Sign in view
            <>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              error={emailError}
              helperText={emailError ? "Invalid email." : ""}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
            />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={passwordError}
                helperText={passwordError}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </>
          )}
        </Box>
        </Box>
        <Button
          fullWidth
          variant="text"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => setIsResettingPassword((prev) => !prev)}
        >
          {isResettingPassword ? 'Back to Sign In' : 'Forgot password?'}
        </Button>
      </Container>
    </ThemeProvider>
  );
}
