import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import { Typography, Grid, TextField, FormControlLabel, Checkbox, Box } from '@mui/material';
import { convertKeysToCamelCase } from '../../utils/misc'; // Assuming this utility function is available
import { useQuestionnaireContext } from '../../components/QuestionnaireContext';

export default function RentConsiderations({ updateFormData, reloadTrigger }) {
  const [formState, setFormState] = useState({
    leaseInducements: false,
    incomeBasedTenantDetails: '',
    subsidizedSpace: false,
    serviceProviderDetails: '',
    hasKiosks: false,
    kioskOperation: '',
    kioskServices: '',
    rentBasedOnIncome: false,
    incomeBasedTenantDetails: '',
    rentAdjustmentsExcludeSalesTaxReturns: false,
    leaseTermsMarketConformity: false,
    rentAdjustmentsExcludeSalesTaxReturnsDescribe: '',
    rentAdjustmentsExcludeSalesTaxReturnsListDescribe: '',
    leaseTermsMarketConformityDescribe: ''
  });

  const { selectedQuestionnaireId } = useQuestionnaireContext();

  useEffect(() => {
    const questionnaireId = sessionStorage.getItem('selectedQuestionnaireId');
    if (questionnaireId) {
      axiosInstance.get('/api/rent-considerations/', {
        params: { questionnaire_id: questionnaireId }
      })
      .then(response => {
        const data = convertKeysToCamelCase(response.data);
        setFormState(data);
        // Update your form data here as well
        updateFormData('rentConsiderations', data);
      })
      .catch(error => {
        console.error('Failed to fetch contact info:', error);
        // Handle error appropriately
      });
    }
  },[selectedQuestionnaireId]); // Depend on reloadTrigger to re-run the effect

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: checked
      };
      updateFormData('rentConsiderations', updatedState);
      return updatedState;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => {
      const updatedInfo = { ...prevState, [name]: value };
      updateFormData('rentConsiderations', updatedInfo);
      return updatedInfo;
    });
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Rent Considerations
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="leaseInducements"
                checked={formState.leaseInducements}
                onChange={handleCheckboxChange}
              />
            }
            label="Due to the post Covid environment, have any lease inducements, amenities or additional services been provided to tenants?"
          />
          {formState.leaseInducements && (
          <Box ml={3}>
          <TextField
            id="leaseInducementsDetails"
            name="leaseInducementsDetails"
            label="Please explain"
            fullWidth
            multiline
            rows={3}
            variant="standard"
            value={formState.leaseInducementsDetails}
            onChange={handleInputChange}
          />
          </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="subsidizedSpace"
                checked={formState.subsidizedSpace}
                onChange={handleCheckboxChange}
              />
            }
            label="Is space provided to a service provider at less than a fair market value rent (e.g., free, subsidized, or reduced cost space for a massage booth, kiosk, fitness center, cafeteria, etc.)?"
          />
          {formState.subsidizedSpace && (

          <Box ml={3}>
          <TextField
            id="serviceProviderDetails"
            name="serviceProviderDetails"
            label="Please identify the service provider and briefly describe the contractual arrangement."
            fullWidth
            multiline
            rows={3}
            variant="standard"
            value={formState.serviceProviderDetails}
            onChange={handleInputChange}
          />
          </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="hasKiosks"
                checked={formState.hasKiosks}
                onChange={handleCheckboxChange}
              />
            }
            label="Does the Property contain kiosks?"
          />
          {formState.hasKiosks && (
            <React.Fragment>
              <Box ml={3}>
                <TextField
                  id="kioskOperation"
                  label="Is the space for the kiosk leased to a third party for operation?"
                  name="kioskOperation"
                  fullWidth
                  multiline
                  rows={2}
                  variant="standard"
                  value={formState.kioskOperation}
                  onChange={handleInputChange}
                />
              </Box>
              <Box ml={3}>
                <TextField
                  id="kioskServices"
                  name="kioskServices"
                  label="Does the Property provide any services in conjunction with the kiosks other than renting of space and utilities?"
                  fullWidth
                  multiline
                  rows={2}
                  variant="standard"
                  value={formState.kioskServices}
                  onChange={handleInputChange}
                />
              </Box>
            </React.Fragment>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="rentBasedOnIncome"
                checked={formState.rentBasedOnIncome}
                onChange={handleCheckboxChange}
              />
            }
            label="Is rent from any of the Property's tenants based in whole or in part on net income or profits?"
          />
          {formState.rentBasedOnIncome && (
          <Box ml={3}>
            <TextField
              id="incomeBasedTenantDetails"
              name="incomeBasedTenantDetails"
              label="Please identify the tenant."
              fullWidth
              variant="standard"
              value={formState.incomeBasedTenantDetails}
              onChange={handleInputChange}
            />
          </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="rentAdjustmentsExcludeSalesTaxReturns"
                checked={formState.rentAdjustmentsExcludeSalesTaxReturns}
                onChange={handleCheckboxChange}
              />
            }
            label="If the rent payable by any tenant, subtenant, or other person is calculated, in whole or in part, by reference to sales revenue, is the measure of receipts or sales used to calculate rental payments adjusted for any reason, other than to take into account sales taxes or returned merchandise?"
          />
        </Grid>
        {formState.rentAdjustmentsExcludeSalesTaxReturns && (
        <>
          <Grid item xs={12} ml={3}>
          <TextField
            id="rentAdjustmentsExcludeSalesTaxReturnsDescribe"
            name="rentAdjustmentsExcludeSalesTaxReturnsDescribe"
            label="Please identify the tenant"
            fullWidth
            multiline
            rows={2}
            variant="standard"
            onChange={handleInputChange}
            value={formState.rentAdjustmentsExcludeSalesTaxReturnsDescribe}
          />
          </Grid>
          <Grid item xs={12} ml={3}>
          <TextField
            id="rentAdjustmentsExcludeSalesTaxReturnsListDescribe"
            name="rentAdjustmentsExcludeSalesTaxReturnsListDescribe"
            label="List all items that adjust the measure of receipts or sales"
            fullWidth
            multiline
            rows={2}
            variant="standard"
            onChange={handleInputChange}
            value={formState.rentAdjustmentsExcludeSalesTaxReturnsListDescribe}
          />
          </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="leaseTermsMarketConformity"
                checked={formState.leaseTermsMarketConformity}
                onChange={handleCheckboxChange}
              />
            }
            label="Are the terms of all lease payments and rent formulae typical and customary for properties of a character and quality similar to the subject property that are located in the same geographic market, and do these provisions conform to normal business practice?"
          />
        </Grid>

      {! formState.leaseTermsMarketConformity && (
        <Grid item xs={12} ml={3}>
          <TextField
            id="leaseTermsMarketConformityDescribe"
            name="leaseTermsMarketConformityDescribe"
            label="Please describe the noncustomary provisions and identify the leases(#) where they appear."
            fullWidth
            multiline
            rows={3}
            variant="standard"
            value={formState.leaseTermsMarketConformityDescribe}
            onChange={handleInputChange}
          />
        </Grid>
      )}
      </Grid>
    </React.Fragment>

  );
}
