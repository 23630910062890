import { jwtDecode } from "jwt-decode";
import { refreshAccessToken } from './api';  // Assuming this function exists and handles refreshing of tokens

// Function to decode a JWT token and extract its payload
const decodeJwt = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    console.error('Failed to decode JWT:', error);
    return null;
  }
};

// Function that checks token expiry and handles automatic logout
export const checkTokenExpiry = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  if (accessToken) {
    const decoded = decodeJwt(accessToken);
    if (decoded && Date.now() >= decoded.exp * 1000) {
      await refreshAccessToken();
    }
  }

  if (refreshToken) {
    const decoded = decodeJwt(refreshToken);
    if (!decoded || Date.now() >= decoded.exp * 1000) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.href = '/login';  // Or use your routing mechanism to redirect
    }
  }
};

// Call this function when the app initializes and at regular intervals
checkTokenExpiry();
setInterval(checkTokenExpiry, 60000);  // Check every minute, adjust as needed
