import React, { useState, useEffect } from 'react';
import {CssBaseline,AppBar,Box,Container,Toolbar,Paper,Stepper,Step,StepLabel,Button,Link,Typography, MobileStepper,IconButton} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import ContactInformation from './ContactInformation';
import RentConsiderations from './RentConsiderations';
import TransportationAndParking from './TransportationAndParking'
import PropertyAmenities from './PropertyAmenities'
import OtherTenantServices from './OtherTenantServices'
import TenantOperations from './TenantOperations'
import Other from './Other'
import Confirm from './Confirm';
import { convertKeysToSnakeCase } from '../../utils/misc';
import axiosInstance from '../../utils/axios';
import isEqual from 'lodash/isEqual';
import SettingsIcon from '@mui/icons-material/Settings'; // Import the Settings icon
import QuestionnaireModal from '../../components/QuestionnaireModal';
import { QuestionnaireContext } from '../../components/QuestionnaireContext';
import { useQuestionnaireContext } from '../../components/QuestionnaireContext';
import { CompletionModal } from '../../components/CompletionModal';

import { ReactComponent as Logo } from '../../assets/images/logo.svg'; // ensure path to your svg file is correct 

import { ToggleColorModeProvider, ThemeToggleButton } from '../../components/ThemeToggle'; // Adjust the path as necessary

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { transform } from 'lodash';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const apiEndpointMapping = {
  contactInformation: '/api/contact-information/',
  rentConsiderations: '/api/rent-considerations/',
  transportationAndParking: '/api/transportation-and-parking/',
  propertyAmenities: '/api/property-amenities/',
  otherTenantServices: '/api/other-tenant-services/',
  tenantOperations: '/api/tenant-operations/',
  other: '/api/other/',
  confirm: '/api/questionnaire/'
};


const steps = ['Contact Information', 'Rent Considerations','Transportation and Parking','Property Amenities','Other Tenant Services', 'Tenant Operations','Other', 'Confirm'];

function getStepContent(step, updateFormData, formDataForCurrentStep) {
  switch (step) {
    case 0:
      return <ContactInformation contactInfo={formDataForCurrentStep} updateFormData={updateFormData} />;
    case 1:
      return <RentConsiderations rentConsiderations={formDataForCurrentStep} updateFormData={updateFormData} />;
    case 2:
      return <TransportationAndParking transportationAndParking={formDataForCurrentStep} updateFormData={updateFormData}/>;
    case 3:
      return <PropertyAmenities propertyAmenities={formDataForCurrentStep} updateFormData={updateFormData}/>;
    case 4:
      return <OtherTenantServices otherTenantServices={formDataForCurrentStep} updateFormData={updateFormData}/>;
    case 5:
      return <TenantOperations tenantOperations={formDataForCurrentStep} updateFormData={updateFormData}/>;
    case 6:
      return <Other other={formDataForCurrentStep} updateFormData={updateFormData}/>;
    case 7:
      return <Confirm confirm={formDataForCurrentStep} updateFormData={updateFormData}/>;
    default:
      throw new Error('Unknown step');
  }
}

const transformOtherTenantServicesData = (data) => {
  // Start with a shallow copy of the data that leaves out the nested structures
  let transformedData = { ...data };
  delete transformedData.billedSeparately;
  delete transformedData.servicesProvided;
  let billedSeparately = data.billedSeparately
  let servicesProvided = data.servicesProvided

  delete data.billedSeparately
  delete data.servicesProvided

  transformedData = convertKeysToSnakeCase(transformedData)
 
  const camelToSnake = (str) => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  };

  // Process 'billedSeparately' and 'servicesProvided'
  Object.entries(billedSeparately).forEach(([key, value]) => {
    const snakeKey = camelToSnake(key);
    transformedData[`billed_separately${snakeKey}`] = value;
  });

  Object.entries(servicesProvided).forEach(([key, value]) => {
    const snakeKey =camelToSnake(key);
    transformedData[`services_provided${snakeKey}`] = value;
  });


  return transformedData;
};

export default function PropertyQuestionnaire() {

  const { selectedQuestionnaireId } = useQuestionnaireContext();
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [isQuestionnaireModalOpen, setIsQuestionnaireModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isCompletionModalOpen, setIsCompletionModalOpen] = useState(false);
  const [hasStepDataChanged, setHasStepDataChanged] = useState({});
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState(null);

  const [formData, setFormData] = useState({
    contactInformation: {},
    rentConsiderations: {},
    transportationAndParking: {},
    propertyAmenities: {},
    otherTenantServices: {},
    tenantOperations:{},
    other:{}
  });

  const [isInitialQuestionnaireSelected, setIsInitialQuestionnaireSelected] = useState(false);
  const handleOpenModal = () => {
    setIsQuestionnaireModalOpen(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setIsQuestionnaireModalOpen(false);
  };

  const handleQuestionnaireSelect = (questionnaireId) => {
    // This update will cause a re-fetch of data in ContactInformation via useEffect
    setReloadTrigger(prev => prev + 1);
  };
  const handleQuestionnaireSelected = (questionnaireId) => {
    sessionStorage.setItem('selectedQuestionnaireId', questionnaireId);
    setIsInitialQuestionnaireSelected(true); // Now the page will load the content
  };
  const updateFormData = (stepName, stepData) => {
    setFormData((prevFormData) => {
      const dataChanged = !isEqual(prevFormData[stepName], stepData); // where isEqual is a deep comparison
      setHasStepDataChanged((prevHasChanged) => ({
        ...prevHasChanged,
        [stepName]: dataChanged,
      }));
      return {
        ...prevFormData,
        [stepName]: stepData,
      };
    });
  };
  useEffect(() => {
    const fetchQuestionnaire = async () => {
      try {
      const response = await axiosInstance.get('/api/questionnaire/', {
        params: { questionnaire_id: selectedQuestionnaireId }
      })
        setCurrentQuestionnaire(response.data);
      } catch (error) {
        console.error('Error fetching questionnaire data:', error);
        // Handle the error appropriately
      }
    };
    if (selectedQuestionnaireId) {
      fetchQuestionnaire();
    }
  }, [selectedQuestionnaireId]);


const handleSubmit = async (stepName) => {
  if (!hasStepDataChanged[stepName]) {
    console.log(`No changes detected in step: ${stepName}, skipping submit.`);
    return;
  }
  
  let dataForStep = formData[stepName];


  // Check if the current step is 'otherTenantServices' and apply specific transformation
  if (stepName === 'otherTenantServices') {
    dataForStep = transformOtherTenantServicesData(dataForStep);
  }
  if (stepName === 'confirm') {
      if(dataForStep.status === true){
        dataForStep.status ='completed'
        dataForStep.date_completed = new Date().toISOString();  // Set the current date/time as the completion date
      }
      else{
        dataForStep.status = 'in_progress'
        dataForStep.date_completed = null;           // And set dateCompleted to null
      }
  }

  else {
    // Otherwise, convert the keys for other steps to snake_case
    dataForStep = convertKeysToSnakeCase(dataForStep);
  }

  const endpoint = apiEndpointMapping[stepName];
  const questionnaireId = sessionStorage.getItem('selectedQuestionnaireId');

  try {
    const params = new URLSearchParams();
    params.append('questionnaire_id', questionnaireId);
    const response = await axiosInstance.post(endpoint, dataForStep, { params });
    console.log('Form data submitted successfully for step:', stepName, response.data);
    if (stepName === 'confirm' && dataForStep.status ==='completed') {
      setIsCompletionModalOpen(true);
    }
    if(stepName==='contactInformation'){
      setCurrentQuestionnaire(dataForStep)
    }
  } catch (error) {
    console.error('Failed to submit form data for step:', stepName, error);
  }
};

  const handleNext = async () => {
    // ... validation if needed

    // Submit the data before going to the next step
    await handleSubmit(getStepName(activeStep));

    // After successful submission, navigate to the next step
    setActiveStep(currentStep => currentStep + 1);
  };

  // Helper function to get the step name based on the index, used in handleSubmit()
  const getStepName = index => {
    const stepNames = Object.keys(apiEndpointMapping);
    return stepNames[index];
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStep = (step) => () => {
    handleSubmit(getStepName(activeStep));
    setActiveStep(step); // Set the active step to the step index that was clicked
  };
  return (
    <ToggleColorModeProvider> {/* Provides the theme context and state */}
    <React.Fragment>
        <CssBaseline />
        <AppBar
          position="absolute"
          color="default"
          elevation={0}
          sx={{
            position: 'relative',
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
          }}
        >
        <Toolbar>
          {/* Logo on the left */}
          <Typography variant="h6" color="inherit" noWrap>
            <Logo style={{ width: '115px', height: 'auto' }} />
          </Typography>

          {/* Space between the logo and the name */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Current name of the questionnaire */}

          {/* Space between the name and the settings */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Settings icon on the right */}
          <IconButton onClick={handleOpenModal} color="inherit" aria-label="open questionnaire settings">
            <SettingsIcon />
          </IconButton>
          <ThemeToggleButton /> {/* Theme toggle button */}
          </Toolbar>
        </AppBar>
        <Container component="main" maxWidth="xlg" sx={{ mb: 4 }}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
              {currentQuestionnaire?.contact_information?.name_of_property || currentQuestionnaire?.name_of_property||'Property Name Unavailable'}
            </Typography>
            <MobileStepper
              variant="dots"
              steps={steps.length}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === steps.length - 1}>
                  Next
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
              sx={{
                  display: { xs: 'flex', lg: 'none' }, // Show on xs to md, hide on lg and up
                justifyContent: 'center',
                background: 'none', // Remove background styling if desired
              }}
            />
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, display: { xs: 'none', lg: 'flex' } }}>
              {steps.map((label, index) => (
                <Step key={label} onClick={handleStep(index)} sx={{ cursor: 'pointer' }}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Your Questionnaire is marked as complete.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, updateFormData, formData[steps[activeStep].toLowerCase().replace(/\s+/g, '')])}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1, bgcolor:'#004b87'}}
                  >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Paper>
        </Container>
        <QuestionnaireModal
          isOpen={isQuestionnaireModalOpen}
          onClose={handleCloseModal}
          onQuestionnaireSelect={handleQuestionnaireSelect} // Pass the selection handling function
        />
        <CompletionModal
          open={isCompletionModalOpen}
          onClose={() => setIsCompletionModalOpen(false)}
        />
    </React.Fragment>
    </ToggleColorModeProvider>
  );
}
