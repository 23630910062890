import React, { useState, useEffect } from 'react';
import { Typography, Grid, TextField, FormControlLabel, Checkbox, Box} from '@mui/material';

import axiosInstance from '../../utils/axios';
import { convertKeysToCamelCase } from '../../utils/misc'; // Assuming this utility function is available
import { useQuestionnaireContext } from '../../components/QuestionnaireContext';

export default function OtherTenantServices({updateFormData, reloadTrigger}) {
  const baseServices = {
    PremiumLightBulbReplacement: false,
    PlumbingWork: false,
    LocksmithServices: false,
    PaintingTenantSpace: false,
    CarpetingTenantSpace: false,
    PictureHanging: false,
    ExcessRubbishRemoval: false,
    InteriorGlassReplacement: false,
    AssistTenantMoves: false,
    SecurityServices: false,  // Add missing service securityServices if necessary
    HandymanServices: false,
  };

  const [formState, setFormState] = useState({
    providesUtilities: false,
    providesCleaningServices: false,
    chargedForCleaning: false,
    providesImprovementServices: false,
    propertyPerformsConstruction: false,
    nonCustomaryServices: '',
    reservedParkingProvided: false,
    valetServiceProvided: false,
    carWashServiceProvided: false,
    providesNonCustomaryServices: false,
    nonCustomaryServicesDetails: '',
    billedSeparately: { ...baseServices },
    servicesProvided: { ...baseServices },
  });


  // Extract the transformation logic into a separate function

  const { selectedQuestionnaireId } = useQuestionnaireContext();

useEffect(() => {
  const questionnaireId = sessionStorage.getItem('selectedQuestionnaireId');
  if (questionnaireId) {
    axiosInstance.get('/api/other-tenant-services/', {
      params: { questionnaire_id: questionnaireId }
    })
    .then(response => {
      const rawData = convertKeysToCamelCase(response.data);
      
      // Initialize the new structured objects
      const newServicesProvided = { ...baseServices };
      const newBilledSeparately = { ...baseServices };

      // Helper functions to determine if a key belongs to specific structures
      const isServicesProvidedKey = key => key.startsWith('servicesProvided');
      const isBilledSeparatelyKey = key => key.startsWith('billedSeparately');

      // Helper function to convert snake_case to CamelCase
      const snakeToCamel = (s) => {
        return s.replace(/(_\w)/g, (m) => ( m[1].toUpperCase() + (m[2] || '').toUpperCase() ));
      };

      // Extract and rename the keys accordingly and populate the new structures.
      // Skip related keys from the original rawData, as they'll be reconstructed.
      const nonServiceKeys = Object.keys(rawData).filter(key =>
        !(isServicesProvidedKey(key) || isBilledSeparatelyKey(key))
      );

      const updatedState = nonServiceKeys.reduce((acc, key) => {
        acc[key] = rawData[key];
        return acc;
      }, {});

      // Mapping the fields into the respective new structures.
      Object.keys(rawData).forEach((key) => {
        if (isServicesProvidedKey(key)) {
          const newKey = snakeToCamel(key.replace('servicesProvided', ''));
          newServicesProvided[newKey] = rawData[key];
        } else if (isBilledSeparatelyKey(key)) {
          const newKey = snakeToCamel(key.replace('billedSeparately', ''));
          newBilledSeparately[newKey] = rawData[key];
        }
      });

      // Adding the new structured objects into the updatedState.
      updatedState.billedSeparately = newBilledSeparately;
      updatedState.servicesProvided = newServicesProvided;

      // Update the local form state
      setFormState(updatedState);

      // If needed, update the parent component's state with the formatted data
      updateFormData('otherTenantServices', updatedState);
    })
    .catch(error => {
      console.error('Failed to fetch data:', error);
    });
  }
}, [selectedQuestionnaireId]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: checked
      };

      updateFormData('otherTenantServices', updatedState);
      return updatedState;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => {
      const updatedInfo = { ...prevState, [name]: value };
      updateFormData('otherTenantServices', updatedInfo);
      return updatedInfo;
    });
  };

const handleServiceChange = (serviceKey) => (event) => {
  const { checked } = event.target;
  setFormState((prevState) => {
    // Updated `servicesProvided` directly
    const updatedServicesProvided = {
      ...prevState.servicesProvided,
      [serviceKey]: checked,
    };
    
    // Update `billedSeparately` directly if the checkbox is unchecked
    const updatedBilledSeparately = !checked ? {
      ...prevState.billedSeparately,
      [serviceKey]: false,
    } : prevState.billedSeparately;
    
    // Update the component's state with the new values
    const updatedState = {
      ...prevState,
      servicesProvided: updatedServicesProvided,
      billedSeparately: updatedBilledSeparately,
    };
    
    // Update the form data in the parent component
    updateFormData('otherTenantServices', updatedState); // Assuming updateFormData accepts the nested structure directly
    
    return updatedState;
  });
};

const handleBillingChange = (serviceKey) => (event) => {
  const { checked } = event.target;
  setFormState((prevState) => {
    const updatedBilledSeparately = {
      ...prevState.billedSeparately,
      [serviceKey]: checked,
    };

    const updatedState = {
      ...prevState,
      billedSeparately: updatedBilledSeparately,
    };

    // Directly update the form data in the parent component's state
    updateFormData('otherTenantServices', updatedState);

    return updatedState;
  });
};

const isAnyServiceProvided = () => {
  return Object.values(formState.servicesProvided).some(service => service);
};

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="providesUtilities"
                  checked={formState.providesUtilities || false}
                  onChange={handleCheckboxChange}
                />
              }
              label="Does the Property provide tenants with Water, Heat, Light, A/C, etc.?"
            />
          </Grid>
          {formState.providesUtilities && (
            <>
              <Grid item xs={12} ml={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="utilitiesProvidedMarket"
                      checked={formState.utilitiesProvidedMarket || false} // Assuming you have this in formState
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Are these services provided by other similar properties in the same geographic region?"
                />
              </Grid>
              <Grid item xs={12} ml={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="utilitiesSubMetered"
                      checked={formState.utilitiesSubMetered || false} // Assuming you have this in formState
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Are these utilities sub-metered? (i.e., bought at wholesale and then sub-metered at retail rates)?"
                />
              </Grid>
            </>
          )}

        {/* Cleaning Services */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="providesCleaningServices"
                checked={formState.providesCleaningServices}
                onChange={handleCheckboxChange}
              />
            }
            label="Does the Property provide above standard cleaning services in the tenant's space (e.g., carpet cleaning, party clean-up, cafeteria clean-up etc.)?"
          />
        </Grid>
        {formState.providesCleaningServices && (
          <>
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="cleaningProvidedMarket"
                    checked={formState.cleaningProvidedMarket || false} // Assuming you have this in formState
                    onChange={handleCheckboxChange}
                  />
                }
                label="Are such services provided at similar properties in the same geographic market?"
              />
            </Grid>
            <Grid item xs={12} ml={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="chargedForCleaning"
                    checked={formState.chargedForCleaning}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Are such services separately charged to the tenants?"
              />
            </Grid>
            {formState.chargedForCleaning && (
              <Grid item xs={12} ml={4}>
                <TextField
                  id="cleaningServiceArrangement"
                  name="cleaningServiceArrangement" // Name property is assumed to match a key in formState
                  label="Please briefly describe the economic arrangements."
                  fullWidth
                  multiline
                  rows={4}
                  variant="standard"
                  value={formState.cleaningServiceArrangement || ''} // Assuming you have this in formState
                  onChange={handleInputChange}
                />
              </Grid>
            )}
          </>
        )}

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="providesImprovementServices"
                  checked={formState.providesImprovementServices}
                  onChange={handleCheckboxChange}
                />
              }
              label="Does the property provide any tenant improvement or construction related services to the tenants?"
            />
          </Grid>
          {formState.providesImprovementServices && (
            <>
              <Grid item xs={12} ml={4}>
                <TextField
                  id="providesImprovementServicesDescription"
                  name="providesImprovementServicesDescription"
                  label="Please provide a brief description of services provided."
                  fullWidth
                  multiline
                  rows={4}
                  variant="standard"
                  value={formState.providesImprovementServicesDescription || ''}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} ml={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      name="propertyPerformsConstruction"
                      checked={formState.propertyPerformsConstruction}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Do the Property employees perform actual construction in tenant spaces (rather than hiring a 3rd party)?"
                />
              </Grid>
            </>
          )}
<Grid item xs={12}>
    <Typography variant="h6">Services provided by the Property:</Typography>
  </Grid>
  {Object.keys(formState.servicesProvided).map((serviceKey) => (
    <Grid item xs={12} key={`service-${serviceKey}`} ml={4}>
      <FormControlLabel
        control={
          <Checkbox
            color="secondary"
            checked={formState.servicesProvided[serviceKey]}
            onChange={handleServiceChange(serviceKey)}
            name={serviceKey}
          />
        }
        label={serviceKey.split(/(?=[A-Z])/).join(" ")} // Split camelCase to words
      />
    </Grid>
  ))}

  {/* Dynamically create checkboxes for billedSeparately if the corresponding service is provided */}
  {isAnyServiceProvided() && (
    <Grid item xs={12}>
      <Typography variant="h6">
        For each service provided, indicate if the property bills the tenant separately:
      </Typography>
    </Grid>
  )}
  {Object.keys(formState.servicesProvided).map((serviceKey) => 
    formState.servicesProvided[serviceKey] && (
      <Grid item xs={12} key={`billing-${serviceKey}`} ml={4}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={formState.billedSeparately[serviceKey]}
              onChange={handleBillingChange(serviceKey)}
              name={`bill-${serviceKey}`}
            />
          }
          label={`Bill for ${serviceKey.split(/(?=[A-Z])/).join(" ")}`} // Split camelCase to words
        />
      </Grid>
    )
  )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="providesNonCustomaryServices"
                  checked={formState.providesNonCustomaryServices}
                  onChange={handleCheckboxChange}
                />
              }
              label="Are services provided other than customary maintenance, cleaning, lighting, and repairs. Such services might include, e.g., collecting charges for parking, security, enforcement of reserved spots, jumpstarts, etc. Please describe:"
            />
            {formState.providesNonCustomaryServices && (
              <Grid item xs={12} ml={4}>
                <TextField
                  id="nonCustomaryServicesDetails"
                  name="nonCustomaryServicesDetails"
                  placeholder="Please describe..."
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleInputChange}
                  value={formState.nonCustomaryServicesDetails || ''}
                />
              </Grid>
            )}
          </Grid>
          {/* Reserved Parking */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="reservedParkingProvided"
                  checked={formState.reservedParkingProvided}
                  onChange={handleCheckboxChange}
                />
              }
              label="Is reserved parking provided?"
            />
          </Grid>
          {/* Valet Service */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="valetServiceProvided"
                  checked={formState.valetServiceProvided}
                  onChange={handleCheckboxChange}
                />
              }
              label="Is a valet service provided?"
            />
          </Grid>
          {/* Car Wash and Detailing Service */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="carWashServiceProvided"
                  checked={formState.carWashServiceProvided}
                  onChange={handleCheckboxChange}
                />
              }
              label="Is a car wash and detailing service provided?"
            />
          </Grid>
        </Grid>
    </React.Fragment>
  );
}
